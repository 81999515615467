import React from "react";

const About3 = () => {
  return (
    <div className="w-screen  gap-3 sm:px-4 gap-x-2 flex flex-col justify-center items-center lg:px-10 ">
      <div class="w-[750px]  text-blue-700 text-[12px] font-semibold font-['Poppins'] leading-[40px]">
        Team members
      </div>
      <div class="w-[750px] my-8 md:flex md:justify-normal md:items-center text-xl text-black text-opacity-60  font-bold font-['Poppins'] leading-[25px]">
        The dream team behind our initiative
      </div>
    </div>
  );
};

export default About3;
