import React from "react";
import kg from "../assets/kg.PNG";

const Donate3 = () => {
  return (
    <div>
      <div
        className="flex items-center justify-center w-screen h-[70vh] bg-cover text-center"
        style={{ backgroundImage: `url(${kg})` }}
      >
        <p
          className=" font-bold text-5xl leading-88 w-[1080px]"
          style={{ fontFamily: "Playfair Display" }}
        >
          “Our goal is to raise funds for these projects, the first of which
          will be given in 2024”
        </p>
      </div>
   
    </div>
  );
};

export default Donate3;
