import React from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import new2 from '../assets/new2.png';


import { Link } from 'react-router-dom'
const Blogtext2 = () => {
  return (
    <div className='w-screen pt-10 flex justify-center items-center '>
        <div className='w-[950px]  '>
          <div className='flex flex-col  p-16  mx-auto mt-5 gap-y-8'>

        <Link to="/blog" className='flex w-40 h-12 border ml-28 sm:ml-0 md:ml-0  bg-zinc-400 text-black items-center justify-center gap-1'>
            
        <AiOutlineArrowLeft className=''/>
            
            <p className='text-black text-xs font-normal font-[DMSans] uppercase leading-snug tracking-wide'>Return to Blogs</p>

          </Link>
            <h1 className=' text-black  md:m-0 px-24 md:p-0 sm:p-0 lg:p-0 text-opacity-90 md:text-[46px] sm:text-[46px] text-[36px] font-semibold font-[Poppins] leading-[68.50px]'>
            The Power of Giving: How Charity Impacts Communities
              </h1>
        </div>
        
      

      <div className='w-[700px] flex gap-y-8  p-16  h-full  flex-col'>
      <img className='md:w-[600px]  w-[600px]  lg:w-[600px]    md:m-0 px-20 md:p-0 sm:p-0 lg:p-0   mx-auto my-4' src={new2} alt='/' />
       <p className='text-neutral-700  text-base  md:m-0 px-24 md:p-0 sm:p-0 lg:p-0 font-normal font-[Poppins] leading-[29.60px]'>
       Charitable giving is a force of compassion that has the power to create lasting positive impacts on local communities around the world. At SEED, we firmly believe in the transformative potential of charity and its ability to uplift the lives of those in need. In this blog, we explore the profound effects of charitable giving on communities and share inspiring success stories from our NGO's initiatives. From providing access to education and healthcare to fostering sustainable development and environmental conservation, we witness the tremendous power of giving in action.
       </p>
       <p className='text-neutral-700 md:m-0 px-24 md:p-0 sm:p-0 lg:p-0 text-[14px] font-normal font-[Poppins] leading-[29.60px]'>
       1. Education: Lighting the Path to a Brighter Future<br/>
Charitable giving in the field of education opens doors of opportunity for countless children and young adults. Our initiative to establish and support schools in underserved areas has empowered local communities with the gift of knowledge. For instance, in the village of Sarvodaya, where access to education was scarce, our NGO established a school that now serves as an educational hub for the entire community. The school has not only improved literacy rates but also instilled hope and aspirations in the hearts of the children, who now dream of becoming doctors, teachers, engineers, and leaders.
       </p>
       <p className='text-neutral-700 md:m-0 px-24 md:p-0 sm:p-0 lg:p-0 text-base font-normal font-[Poppins] leading-[29.60px]'>
       2. Healthcare: Healing the Wounds of Vulnerability<br/>
Through charitable healthcare programs, we have witnessed the power of giving in healing and transforming lives. In partnership with dedicated medical professionals, SEED conducts health camps in remote regions, providing essential medical services to those who lack access to regular healthcare. In one such camp, we encountered Priya, a young girl suffering from a curable medical condition. With timely treatment and care, Priya's health was restored, and her family's burden was alleviated. The impact of charitable healthcare extends far beyond physical healing, as it fosters a sense of well-being and hope within the community.       
       </p>

       <p className='text-neutral-700 md:m-0 px-24 md:p-0 sm:p-0 lg:p-0 text-[14px] font-normal font-[Poppins] leading-[29.60px]'>
       3. Sustainable Development: Empowering Communities for the Long <br/>
Charitable giving in sustainable development projects is a vital catalyst for empowering communities and creating self-sufficient futures. Our work in promoting eco-friendly practices and renewable energy solutions has brought tangible benefits to local communities. In a remote village, we introduced solar-powered water pumps, ensuring a steady water supply for agricultural activities. As a result, farmers witnessed increased crop yields and improved livelihoods, breaking the cycle of poverty and dependency.
       </p>

       <p className='text-neutral-700 md:m-0 px-24 md:p-0 sm:p-0 lg:p-0 text-[14px] font-normal font-[Poppins] leading-[29.60px]'>
       4. Environmental Conservation: Protecting the Planet We Call Home<br/>
Through charitable initiatives focused on environmental conservation, we play a critical role in preserving the planet for future generations. In collaboration with local communities, we have undertaken tree-planting campaigns, restoring degraded lands and promoting biodiversity. In the Sundarbans region, where mangrove forests were disappearing, our efforts not only conserved natural habitats but also protected communities from the devastating impact of natural disasters like cyclones.
       </p>
       <p className='text-neutral-700 md:m-0 px-24 md:p-0 sm:p-0 lg:p-0 text-[14px] font-normal font-[Poppins] leading-[29.60px]'>

       5. Empowerment through Economic Opportunities
Charity-driven economic empowerment initiatives enable individuals to break free from the chains of poverty. Through microfinance programs, we support budding entrepreneurs in starting small businesses and becoming financially self-reliant. A noteworthy example is Ramesh, a skilled artisan who lacked the capital to expand his pottery business. With a microloan from our NGO, Ramesh purchased new equipment and expanded his market reach, transforming his life and inspiring others in his community to pursue their entrepreneurial dreams.<br/>
Conclusion<br/>
The power of giving is a force that transcends borders and unites humanity in a common purpose – to make the world a better place. Through charitable giving, SEED and countless other NGOs continue to impact communities positively and foster sustainable change. The success stories shared here serve as a testament to the profound effects of charitable initiatives on education, healthcare, sustainable development, environmental conservation, and economic empowerment. As we celebrate the power of giving, we invite you to join us on this incredible journey of compassion and impact. Together, let us sow the seeds of positive change, nurture them with kindness and generosity, and watch as they blossom into thriving communities and a brighter future for all. Embrace the power of giving and be a part of the transformational force that touches lives and shapes a better world for generations to come.
       </p>
       
      </div>


        </div>
    </div>
  )
}

export default Blogtext2