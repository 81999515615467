import React from "react";
import Blog1 from "../Blogs/Blog1";
import Blog3 from "../Blogs/Blog3";
import { useScrollToTop } from "../scrool";

const Blog = () => {
  useScrollToTop();
  return (
    <div>
      <Blog3 />

      <Blog1 />
    </div>
  );
};

export default Blog;
