import React from "react";
import g11 from "../assets/S2.png";
import g12 from "../assets/g11.png";
import g13 from "../assets/S11.png";
import { Link } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import zelle from "../assets/zelle_code.png";

import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Donate2 = () => {
  return (
    <div className="w-screen  flex justify-center items-center mb-5">
      <div className="lg:w-[870px] w-full grid grid-cols-1 col-span-3 gap-3 px-4 sm:grid-cols-3 gap-x-2  lg:px-10 ">
        <div className="sm:w-[240px]  w-full flex flex-col justify-center items-center  ">
          <img className="sm:w-[240px] sm:h-[260px] w-full" src={g11} alt="" />
          <div className="sm:w-[230px] w-full mt-4 flex flex-col gap-y-3  ">
            <div class="text-black w-full md:text-[16px] text-[16px] font-semibold font-['Poppins'] leading-10">
              Donate
            </div>
            <div class=" text-black w-full text-opacity-50 text-[11px]  font-normal font-['Poppins'] leading-normal">
              Your contribution today, a better world tomorrow.
            </div>
            <div className="w-[300px] flex mb-10 gap-4">
              <a
                href="https://www.paypal.com/donate/?hosted_button_id=5ZWDHTUJX5LBW"
                className="w-20 h-8 flex justify-center items-center text-[11px]  font-normal font-[Poppins]  rounded-full text-white text-center bg-blue-700"
              >
                {" "}
                Paypal
              </a>

              <ZelleDialog />
            </div>
          </div>
        </div>

        <div className="sm:w-[240px] boder w-full flex flex-col justify-center items-center ">
          <img className="sm:w-[240px] sm:h-[260px] w-full" src={g12} alt="" />
          <div className="sm:w-[230px] mt-4  w-full flex flex-col gap-y-3">
            <div class="text-black  text-[16px] font-semibold font-['Poppins'] leading-10">
              Sponsor an event
            </div>
            <div class=" text-black text-opacity-50 text-[11px]  font-normal font-['Poppins'] leading-normal">
              Be the catalyst for positive change – sponsor our mission.
            </div>
            <div className="w-[230px] mb-10 flex gap-4">
              <a
                href="https://www.paypal.com/donate/?hosted_button_id=5ZWDHTUJX5LBW"
                className="w-20 h-8 flex justify-center text-[11px]  font-normal font-[Poppins] leading-normal items-center rounded-full text-white text-center bg-blue-700"
              >
                {" "}
                Paypal
              </a>
              <ZelleDialog />
            </div>
          </div>
        </div>

        <div className="sm:w-[240px] w-full flex flex-col justify-center items-center ">
          <img className="sm:w-[240px]  sm:h-[260px] w-full" src={g13} alt="" />
          <div className="sm:w-[240px] mt-4 w-full flex flex-col gap-y-3">
            <div class="text-black  text-[16px] font-semibold font-['Poppins'] leading-10">
              Contact Us
            </div>
            <div class=" text-black text-opacity-50 text-[11px]  font-normal font-['Poppins'] leading-normal">
              Get in touch with us by clicking on the button below.{" "}
            </div>
            <div className="w-[300px] mb-10">
              <Link to={"/contact"}>
                <button
                  className="text-black w-20 h-8 hover:bg-lime-400 bg-lime-300 rounded-full justify-start
                text-opacity-60
                        text-[10px]
                        font-semibold
                        font-[Poppins]"
                  type="submit"
                >
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "430px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export function ZelleDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <React.Fragment>
        <button
          className="w-20 h-8 flex justify-center items-center rounded-full text-[11px]  font-normal font-[Poppins] leading-normal text-white text-center bg-violet-700"
          onClick={handleClickOpen}
        >
          Zelle
        </button>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            sx={{ marginTop: 5, paddingTop: 4, padding: 3 }}
            id="customized-dialog-title"
            className="text-center font-poppins"
            style={{
              fontSize: "22px",
              fontWeight: 600,
            }}
          >
            Scan QR Code to Donate
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            className="m-10 p-10"
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[600],
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogContent className="flex items-center justify-center">
            <img className="w-[300px] p-[30px] mx-auto" src={zelle} alt="" />
          </DialogContent>
          <DialogActions></DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    </div>
  );
}
export default Donate2;
