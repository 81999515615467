import React from "react";

import { useScrollToTop } from "../scrool";
const Team = () => {
  useScrollToTop();

  return <div>Team</div>;
};

export default Team;
