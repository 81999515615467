import React from "react";
import Donate1 from "../Donatecomp/Donate1";
import Donate2 from "../Donatecomp/Donate2";
import Donate3 from "../Donatecomp/Donate3";

import { useScrollToTop } from "../scrool";
const Donate = () => {
  useScrollToTop();

  return (
    <div>
      <Donate1 />
      <Donate2 />
      <Donate3 />
    </div>
  );
};

export default Donate;
