import React from "react";

const About5 = () => {
  return (
    <div className="w-screen py-10 flex-col flex justify-center bg-blue-700 text-white items-center">
      <h1
        className="
text-white
text-opacity-90

text-[33px]
font-semibold
font-[Poppins]
leading-[60px]"
      >
        Vision
      </h1>
      <div class="text-white text-opacity-60 text-[14px] font-medium font-['Poppins']">
        We will be providing services using this framework
      </div>
    </div>
  );
};

export default About5;
