import React from 'react'

const Blog3 = () => {
  return (
    <div className='w-screen  bg-white text-[#00000099] flex items-center justify-center    '>
    
       <div className='w-[840px] lg:ml-12 mt-28 p-4 md:justify-center md:items-center '>
         < h1 className='// Blogs & Articles
text-black
text-[32px]

font-bold
font-[Poppins]
leading-[60px]'>
    Blogs & Articles
</h1>
       </div>
    </div>
  )
}

export default Blog3