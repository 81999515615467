import React from "react";
import Contactus from "../contact/Contactus";

import { useScrollToTop } from "../scrool";
const Contact = () => {
  useScrollToTop();

  return (
    <div>
      <Contactus />
    </div>
  );
};

export default Contact;
