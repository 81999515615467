import React from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import new1 from '../assets/new1.png';


import { Link } from 'react-router-dom'
const Blogtext1 = () => {
  return (
    <div className='w-screen pt-10  flex justify-center items-center '>
        <div className='w-[950px]  '>
          <div className='flex flex-col  p-16  mx-auto mt-5 gap-y-8'>

        <Link to="/blog" className='flex w-40 h-12 border ml-28 sm:ml-0 md:ml-0  bg-zinc-200 text-black items-center justify-center gap-1'>
            
        <AiOutlineArrowLeft className=''/>
            
            <p className='text-black text-xs font-normal font-[poppins] uppercase leading-snug tracking-wide'>Return to Blogs</p>

          </Link>
            <h1 className=' text-black  md:m-0 px-24 md:p-0 sm:p-0 lg:p-0 text-opacity-90 md:text-[40px] sm:text-[40px] text-[36px] font-bold font-[Poppins] leading-[50px]'>
            "The Joy of Volunteering: How You Can Make a Difference" 
              </h1>
        </div>
        
      

      <div className='w-[700px] flex gap-y-8  p-16  h-full  flex-col'>
      <img className='md:w-[600px]  w-full  lg:w-[600px]    md:m-0 px-20 md:p-0 sm:p-0 lg:p-0   mx-auto my-4' src={new1} alt='/' />
       <p className='text-neutral-700  text-[14px]  md:m-0 px-24 md:p-0 sm:p-0 lg:p-0 font-normal font-[Poppins] leading-[29.60px]'>
       Volunteering is a powerful force that brings positive change to individuals, communities, and the world at large. At SEED, we believe that everyone has the ability to make a difference, and our volunteering programs provide a unique opportunity for individuals to contribute their time, skills, and passion to meaningful causes. In this blog, we will explore the joy of volunteering and how you can be part of our mission to create a better world. Discover the transformative benefits of giving back to the community and how your actions, no matter how small, can have a profound impact on the lives of others.
       </p>
       <p className='text-neutral-700 md:m-0 px-24 md:p-0 sm:p-0 lg:p-0 text-[14px] font-normal font-[Poppins] leading-[29.60px]'>
       1. A Purpose-Driven Experience<br/>
Volunteering gives you the chance to be part of something greater than yourself. When you join our NGO's volunteering programs, you become part of a compassionate community united by a common goal – making the world a better place. The sense of purpose you gain from contributing to causes like education, healthcare, and environmental sustainability provides an enriching experience that goes beyond personal achievements.
       </p>
       <p className='text-neutral-700 md:m-0 px-24 md:p-0 sm:p-0 lg:p-0 text-[14px] font-normal font-[Poppins] leading-[29.60px]'>
       2. Fostering Connections and Building Relationships<br/>
Volunteering is a bridge that connects people from diverse backgrounds and experiences. It offers a platform to forge meaningful relationships with like-minded individuals who share your passion for giving back. Whether you're working alongside local communities in rural areas or collaborating with volunteers from different parts of the world, the connections you make during your volunteering journey can be incredibly rewarding and long-lasting.
       
       </p>

       <p className='text-neutral-700 md:m-0 px-24 md:p-0 sm:p-0 lg:p-0 text-[14px] font-normal font-[Poppins] leading-[29.60px]'>
       3. Developing and Sharing Skills<br/>
Volunteering is not only an opportunity to give; it's a chance to learn and grow. Our volunteering programs offer a wide range of tasks that can help you develop valuable skills such as leadership, communication, problem-solving, and teamwork. Moreover, you can share your expertise and knowledge to empower others and leave a lasting impact on the communities you serve.
       </p>

       <p className='text-neutral-700 md:m-0 px-24 md:p-0 sm:p-0 lg:p-0 text-[14px] font-normal font-[Poppins] leading-[29.60px]'>

       4. Making a Tangible Impact<br/>
The impact of volunteering is tangible and immediate. Whether you're helping build schools, conducting medical camps, or planting trees for a greener environment, you can see the positive change your efforts bring firsthand. Witnessing the smiles on the faces of children receiving education materials or the gratitude of families receiving essential aid reinforces the significance of your contributions.
       </p>
       <p className='text-neutral-700 md:m-0 px-24 md:p-0 sm:p-0 lg:p-0 text-[14px] font-normal font-[Poppins] leading-[29.60px]'>

       5. Personal Growth and Well-Being<br/>
Giving back to the community not only benefits others but also enriches your own life. Volunteering has been linked to improved mental health, increased happiness, and reduced stress levels. The act of selflessness and the feeling of being part of something bigger create a sense of fulfillment and purpose that can positively impact your overall well-being.

       </p>
       <p className='text-neutral-700 md:m-0 px-24 md:p-0 sm:p-0 lg:p-0 text-[14px] font-normal font-[Poppins] leading-[29.60px]'>
       6. Inspiring Others to Volunteer<br/>
As a volunteer, you become an ambassador for positive change. Your actions and commitment inspire others to get involved, creating a ripple effect of kindness and generosity. When you share your volunteering experiences with family, friends, and colleagues, you ignite a passion for giving back in others, leading to a more compassionate and caring society.<br/>
Conclusion<br/>
The joy of volunteering is unparalleled, and it has the power to transform lives – yours and the lives of those you touch. By becoming a volunteer with SEED, you can be part of our mission to create a world where education, healthcare, and sustainable development reach every corner. Embrace the joy of giving back, and together, let's make a difference that resonates far beyond the boundaries of our volunteering efforts. Your time, skills, and compassion can shape a brighter future, and we invite you to join us on this fulfilling journey of service and impact. Together, we can create a world that thrives on empathy, love, and the collective desire to make a positive change. Are you ready to experience the joy of volunteering and be the change you wish to see in the world?
       </p>
      </div>


        </div>
    </div>
  )
}

export default Blogtext1