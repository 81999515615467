import React from "react";

const Donate1 = () => {
  return (
    <div className="w-screen h-[300px] justify-center pt-4  items-center gap-y-4 bg-white text-[#000000DE] flex flex-col  ">
      <h1
        className="md:text-[30px]  text-black

font-bold
font-[Montserrat]

Content
"
      >
        Donate, Volunteer & Sponsor
      </h1>
      <div className="flex flex-col justify-center items-center text-[#00000099]">
        {" "}
        <p
          className="  text-[13px]  text-black
text-opacity-50

font-normal
font-[Poppins]
 "
        >
          Be a hero to someone in need by donating now
        </p>
      </div>
    </div>
  );
};

export default Donate1;
