import React from 'react'

const Fotter4 = () => {
  return (
    <div className='w-screen hover:bg-slate-900 hidden md:flex bg-black   justify-center items-center '>
        <div className='w-[1000px] h-[1px] bg-[#FFFFFF7A]'></div>

    </div>
  )
}

export default Fotter4