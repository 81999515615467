import React from "react";
import Banner from "../Components/Banner";

import { useScrollToTop } from "../scrool";
const Home = () => {
  useScrollToTop();

  return (
    <div>
      <Banner />

      <HomeBottomContent />
    </div>
  );
};

function HomeBottomContent() {
  return (
    <div className="w-full flex justify-center items-center bg-black">
      <div
        className="w-[850px] m-28 p-6 flex flex-col gap-y-8 text-white 
                      font-normal
                      font-[Montserrat]"
      >
        <div className="">
          Welcome to SEED Inc! We are a non-profit organization that stands for
          Support, Educate, Empower, Discover. The primary mission of this
          organization is to support and educate underprivileged children and
          families in every way possible to empower them and to help discover
          their potential. Additionally, we strive to educate the community
          about mental health to improve emotional well being; to remove stigma
          surrounding mental health issues, remove stigma, and encourage seeking
          guidance and treatment.
        </div>
        <div className="">
          Additionally, we strive to educate the community about mental health
          to improve emotional well being; to remove stigma surrounding mental
          health issues, remove stigma, and encourage seeking guidance and
          treatment.
        </div>
      </div>
    </div>
  );
}

export default Home;
