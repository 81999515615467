import React from "react";
import Aboutbanner from "../Aboutcomp/Aboutbanner";
import About3 from "../Aboutcomp/About3";
import About4 from "../Aboutcomp/About4";
import About5 from "../Aboutcomp/About5";
import About6 from "../Aboutcomp/About6";

import Block from "../Aboutcomp/Block";
import Aboutcard from "../Aboutcomp/Aboutcard";
import Aboutcard1 from "../Aboutcomp/Aboutcard1";

import { useScrollToTop } from "../scrool";
const Aboutus = () => {
  useScrollToTop();

  return (
    <div className="">
      <Aboutbanner />
      {/* Welcome to SEED Inc! We are a non-profit organization */}
      {/* <About2 />  */}
      <div className=" mt-16 ">
        <About3 />
        <Aboutcard />
        <Aboutcard1 />
        {/* <Aboutcard2 /> */}
      </div>
      <About5 />
      <About6 />
      <Block />
      <About4 />
    </div>
  );
};

export default Aboutus;
