import React from "react";
import g11 from "../assets/B1.png";
import g13 from "../assets/B2.png";
import g131 from "../assets/B4.png";
import g12 from "../assets/B3.png";

const Aboutcard = () => {
  return (
    <div className="w-screen m-4 flex justify-center   pr-[30px] sm:pr-0 mb-5">
      <div className="lg:w-[870px] w-full grid grid-cols-1 col-span-3 gap-3 sm:px-4 sm:grid-cols-3 gap-x-2  lg:px-10 ">
        <div className="sm:w-[240px]  w-full flex flex-col rounded-lg shadow ">
          <img
            className="sm:w-[240px] rounded-t-lg sm:h-[190px] w-full"
            src={g11}
            alt=""
          />
          <div className="m-1 p-1 sm:w-[230px] w-full mt-4 flex flex-col gap-y-3 h-[35vh] md:h-[30vh]">
            <div class="text-black w-full md:text-[16px] flex flex-col text-[16px] font-semibold font-['Poppins'] leading-2">
              <h1 className="text-teal-900  text-[28px] sm:text-[16px] font-bold">
                Priya Foster
              </h1>
              <p className="text-[20px] sm:text-[12px] font-normal text-[#27C871] <- green-500">
                Member
              </p>
            </div>
            <div class=" text-black w-full text-opacity-50 text-[11px] flex flex-col   font-normal font-['Poppins'] leading-normal">
              <div class=" text-[10px] text-neutral-500 font-normal font-['Poppins'] leading-[20px]">
                Priya is a licensed professional counselor (LPC), aka a
                therapist. She works with teens and adults and is passionate
                about mental health education for all.{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="sm:w-[240px]  w-full flex flex-col   rounded-lg shadow ">
          <img
            className="sm:w-[240px] rounded-t-lg sm:h-[190px] w-full"
            src={g13}
            alt=""
          />
          <div className="m-1 p-1 sm:w-[230px] w-full mt-4 flex flex-col gap-y-3  h-[35vh] md:h-[30vh]">
            <div class="text-black w-full md:text-[16px] flex flex-col   text-[16px] font-semibold font-['Poppins'] leading-2">
              <h1 className="text-black  text-[28px] sm:text-[16px] font-bold">
                Deepa Subramaniam M.D.
              </h1>
              <p
                className="text-[20px] sm:text-[12px] font-normal text-green-500 <- green-500
"
              >
                Member
              </p>
            </div>
            <div class=" text-black w-full text-opacity-50 text-[11px]  flex flex-col   font-normal font-['Poppins'] leading-normal">
              <div class=" text-[10px] text-neutral-500 font-normal font-['Poppins'] leading-[20px]">
                Deepa is an oncologist, researcher and teacher who is passionate
                about science. She has spent over two decades mentoring children
                and young adults interested in STEM field.
              </div>
            </div>
          </div>
        </div>

        <div className="sm:w-[240px]  w-full flex flex-col rounded-lg shadow ">
          <img
            className="sm:w-[240px] rounded-t-lg sm:h-[190px] w-full"
            src={g131}
            alt=""
          />
          <div className="m-2 p-2 sm:w-[230px] w-full mt-4 flex flex-col gap-y-3 h-[35vh] md:h-[30vh] ">
            <div class="text-black w-full md:text-[16px] flex flex-col text-[16px] font-semibold font-['Poppins'] leading-2">
              <h1 className="text-black text-[28px] sm:text-[16px] font-bold">
                Arun Maran
              </h1>
              <p className="text-[20px] sm:text-[12px] font-normal text-green-500 <- green-500">
                Member
              </p>
            </div>
            <div class=" text-black w-full text-opacity-50 text-[11px] flex flex-col   font-normal font-['Poppins'] leading-normal">
              <div class=" text-[10px] text-neutral-500 font-normal font-['Poppins'] leading-[20px]">
                Arun graduated from the University of Maryland in 2020 is
                currently a full stack software engineer in New York City. He
                has been a tutor in STEM subjects since age 14.
              </div>
            </div>
          </div>
        </div>
        <div className="sm:w-[240px]  w-full flex flex-col rounded-lg shadow ">
          <img
            className="sm:w-[240px] rounded-t-lg sm:h-[190px] w-full"
            src={g12}
            alt=""
          />
          <div className="m-2 p-2 sm:w-[230px] w-full mt-4 flex flex-col gap-y-3 h-[35vh] md:h-[30vh] ">
            <div class="text-black w-full md:text-[16px] flex flex-col text-[16px] font-semibold font-['Poppins'] leading-2">
              <h1 className="text-black text-[28px] sm:text-[16px] font-bold">
                Asha Maran
              </h1>
              <p
                className="text-[20px] sm:text-[12px] font-normal text-green-500 <- green-500
"
              >
                Member
              </p>
            </div>
            <div class=" text-black w-full text-opacity-50 text-[19px] flex flex-col font-normal font-['Poppins'] leading-normal">
              <div class=" text-[10px] text-neutral-500 font-normal font-['Poppins'] leading-[20px]">
                Asha is a recent college graduate from University of Virginia
                working in software engineering. She works hard to help others
                in times of need.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutcard;
