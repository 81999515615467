import { useEffect } from "react";
export function useScrollToTop() {
  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    };
    handleScrollToTop();
  }, []);
}
