import React from 'react'

const Fotter3 = () => {
  return (
    <div className='w-screen flex justify-center  items-center bg-black text-white  py-5 mb-3'>
        <h1 className='w-[800px] text-opacity-90
text-[12px]
font-normal
font-[Montserrat]

  hidden md:flex mb-3'>
        @ 2023, Seed.org, All rights reserved.
        </h1>
    </div>
  )
}

export default Fotter3